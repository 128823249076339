#wp-checkout {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background-color: #f6f6f6;

    .logo {
        max-width: 400px;
        width: 100%;
        box-shadow: 3px 3px 60px 0px rgba(0, 0, 0, 0.1);

        img {
            width: 100%;
        }
    }

    .card {
        background: white;
        padding: 20px 30px;
        top: -30px;
        width: 100%;
        max-width: 400px;
        box-shadow: 3px 3px 60px 0px rgba(0, 0, 0, 0.1);
    }

    .amount {
        display: flex;
        justify-content: end;
    }

    .field {
        height: 40px;
        border-bottom: 1px solid lightgray;
    }

    .columns {
        display: flex;
        justify-content: space-between;

        .field:nth-of-type(1) {
            margin-right: 20px;
        }
    }

    .field.is-onfocus {
        border-color: black;
    }

    .field.is-empty {
        border-color: orange;
    }

    .field.is-invalid {
        border-color: red;
    }

    .field.is-valid {
        border-color: #262161;
    }

    #card-pan {
        margin-bottom: 30px;
    }

    .submit {
        background: #262161;
        cursor: pointer;
        width: 200px;
        margin-top: 30px;
        color: white;
        outline: 0;
        font-size: 14px;
        border: 0;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 15px 0;
        transition: background 0.3s ease;
    }

    .submit[disabled] {
        color: #00000040;
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
        cursor: not-allowed;
    }

    .clear {
        background: transparent;
        cursor: pointer;
        width: 100px;
        margin-top: 30px;
        color: #262161;
        font-size: 14px;
        border: 1px solid #262161;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 15px 0;
        transition: background 0.3s ease;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
    }

    .info {
        width: 400px;
        margin-top: 20px;
    }
}

